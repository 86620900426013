import type { FieldConfig, FieldOptions, FormConfig } from "./types";

export const sanitizeHtml = (target: string | null) => {
  if (target) {
    const replaced = target
      .replace("&", "&amp;")
      .replace("'", "&#x27;")
      .replace("`", "&#x60;")
      .replace('"', "&quot;")
      .replace("<", "&lt;")
      .replace(">", "&gt;");

    return replaced;
  } else return "";
};

// 新規html要素作成と属性の登録を一緒に行う関数。 createDropdownButton()で使用。
// 属性 : attrs  例：{'class' : 'item w-100}
const ATTR_WHITE_LIST = ["class", "id"];
export const createElwithAttr = (el: string, attrs: Record<string, string>) => {
  var newElement = document.createElement(el);
  for (const [key, value] of Object.entries(attrs)) {
    if (ATTR_WHITE_LIST.includes(key)) {
      newElement.setAttribute(key, value);
    } else if (key === "href" || key === "src") {
      newElement.setAttribute(key, encodeURI(value));
    } else {
      newElement.setAttribute(key, sanitizeHtml(value));
    }
  }
  return newElement;
};

/** 数値を指定の桁数で０字詰めする */
export const zeroPad = (num: number, maxLength: number, padString = '0') => {
  return num.toString().padStart(maxLength, padString)
}


/** 改行をbrに変換 */
export const linebreakToBr = (str) => {
  let result = ''
  if (str) result = str.replace(/(\r\n|\r|\n)/g, '<br>');
  return result
}


const basicColors = [
  [255, 102, 102], // red
  [102, 255, 255], // pale blue
  [255, 178, 102], //orange
  [102, 178, 255], //blue
  [255, 255, 102], // yellow
  [102, 102, 255], // blue berry
  [178, 255, 102], // pale green
  [178, 102, 255], // grape
  [102, 255, 102], // green apple
  [255, 102, 255], // pink
  [102, 255, 178], // lime
  [255, 102, 178], // strrow berry
];
export const generateColors = (length: number, opacity: number) => {
  let colors: { background: string[]; border: string[] } = {
    background: [],
    border: [],
  };
  // 色数を最大48色に制限
  const numOfColors = length > 48 ? 48 : length;
  for (let i = 0; i < numOfColors; i += 1) {
    const index = i % 12;
    const percentage = 0.1 * Math.floor((i + 1) / 12);
    const basicColor = basicColors[index];
    const r =
      basicColor[0] + Math.floor(256 * percentage) < 255
        ? basicColor[0] + Math.floor(256 * percentage)
        : 255;
    const g =
      basicColor[1] + Math.floor(256 * percentage) < 255
        ? basicColor[1] + Math.floor(256 * percentage)
        : 255;
    const b =
      basicColor[2] + Math.floor(256 * percentage) < 255
        ? basicColor[2] + Math.floor(256 * percentage)
        : 255;
    colors.background.push(`rgba(${r}, ${g}, ${b}, ${opacity})`);
    colors.border.push(`rgba(${r}, ${g}, ${b}, 1.0)`);
  }
  return colors;
};

export const generateColorsHex = (length: number, opacity: number, type: 'hex' | 'rgb') => {
  let colors: { background: string[]; border: string[] } = {
    background: [],
    border: [],
  };
  // 色数を最大48色に制限
  const numOfColors = length > 48 ? 48 : length;
  const numOfRows = Math.ceil(numOfColors / basicColors.length)

  const opacHex = ((opacity * 255) | 1 << 8).toString(16).slice(1)
  let bg = []
  let bor = []
  for (let i = 0; i < numOfRows; i += 1) {
    const index = i % 12;
    const percentage = 40 * i;

    const newRow = basicColors.map(arr => {
      const newArr = arr.map(num => {
        // console.log(Math.ceil(num + percentage) % 255)
        const newNum = Math.abs(num - percentage) < 256
          ? Math.abs(num - percentage)
          : Math.abs(num - percentage) % 255
        return type === 'hex' ? (newNum | 1 << 8).toString(16).slice(1) : newNum
      })
      // console.log('newArr: ', newArr)

      if (type === 'hex') {
        bg.push(`#${newArr.join('')}${opacHex}`)
        bor.push(`#${newArr.join('')}ff`)
      } else {
        bg.push(`rgba(${newArr[0]}, ${newArr[1]}, ${newArr[2]}, ${opacity})`);
        bor.push(`rgba(${newArr[0]}, ${newArr[1]}, ${newArr[2]}, 1.0)`);
      }
    })
    // console.log(bg)

    colors.background = bg;
    colors.border = bor;
  }
  // console.log('colors: ', colors)
  return colors;
}

export const rgba2hex = (orig) => {
  let a, isPercent,
    rgb = orig.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
    alpha = (rgb && rgb[4] || "").trim(),
    hex = rgb ?
      (rgb[1] | 1 << 8).toString(16).slice(1) +
      (rgb[2] | 1 << 8).toString(16).slice(1) +
      (rgb[3] | 1 << 8).toString(16).slice(1) : orig;

  if (alpha !== "") {
    a = alpha;
  } else {
    a = 1;
  }
  // multiply before convert to HEX
  a = ((a * 255) | 1 << 8).toString(16).slice(1)
  hex = hex + a;

  return hex;
}

/** inputコンポーネントのカスタムバリデーションを設定しない場合は常に空のinvalidMessageを返すバリデーションをかませる */
export const defaultValidator = (value) => {
  return '';
};

export const defaultFieldOptions: FieldOptions = {
  type: 'string',
  read_only: false,
  label: '',
  required: false,
};

/** フォームの入力欄の初期設定値に使用できるコンフィグ。
 * スプレッド構文でこの初期値を設定し、カスタマイズしたい値のみを
 * 再度記述することでコードの量を削減できる。
 * 例： customConfig = {...defaultFieldConfig, helperText: 'abc'}
 */
export const defaultFieldConfig: FieldConfig = {
  component: undefined,
  initialValue: undefined,
  placeHolder: '',
  helperText: '',
  options: { ...defaultFieldOptions },
  input: undefined,
  is_valid: false,
  name: '',
  inputType: null,
  inputStyle: null,
  inputClass: 'mt-3',
  label_display: true,
  display: true,
  customValidation: defaultValidator,
}

/** formConfigからRowキーを除き、key: fieldConfig 形式のObjectに変換する */
export const mergeFormConfig = (
  formConfig: FormConfig,
): Record<string, FieldConfig> => {
  let res = {};
  Object.values(formConfig).forEach((conf) => (res = { ...res, ...conf }));
  return res;
};

/** EditableTableで使用。入力されたOjectを指定されたキーの要素で文字列を作成し、返却する。
 *入力はObjectのArrayでも可。 
*/
export const makeObjectStr = (obj: Record<string, any>[] | Record<string, any>, keys: string[] | string) => {
  let returnVal = ''
  if (Array.isArray(obj)) {
    obj.forEach((child) => {
      returnVal.length ? returnVal += ` , ${objToString(child, keys)}` : returnVal = objToString(child, keys)
    })
  } else {
    returnVal = objToString(obj, keys)
  }
  return returnVal
}
export const objToString = (obj: Record<string, any>, keys: string[] | string) => {
  let returnVal = ''
  if (Array.isArray(keys)) {
    keys.forEach((key) => {
      returnVal.length ? returnVal += `(${obj[key]}) ` : returnVal = `${obj[key]} `
    })
  } else returnVal = obj[keys]
  return returnVal
}

/** fetchで帰ってきたエラーResponseを表示できる形にパースする。 */
export const parseResponseError = (obj: Response) => {
  const keys = ['url', 'ok', 'status', 'statusText']
  let returnVal = '以下のリクエストでエラーが発生しました。\n'
  keys.forEach((key) => {
    returnVal += `${key}: ${obj[key]}\n`
  })
  return returnVal
}

/** blobデータをダウンロードする。blob -> urlに変換 -> aタグを新規作成してhrefにセット -> ダウンロードする */
export const downloadBlob = (blob, fileName: string) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.download = fileName;
  a.href = url;
  a.click();
  a.remove();
  setTimeout(() => {
    URL.revokeObjectURL(url);
  }, 1E4);
}

